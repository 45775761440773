@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

@import "~bootstrap/scss/bootstrap";

html,
body {
	margin: 0px !important;
	padding: 0px !important;
	background-color: #f3f5f8;
	font-family: "Inter", sans-serif !important;
}

.container {
	display: flex;
	flex-direction: column;
	margin: auto;
	width: 100%;
	min-width: 100%;
	min-height: 100vh;
	padding: 0px !important;
}

.containerRow {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.fadeInUpContent {
	-webkit-animation-name: fadeInUpContent;
	animation-name: fadeInUpContent;
}

::-webkit-scrollbar {
	width: 6px;
	height: 8px;
	border-radius: 50%;
}

::-webkit-scrollbar-button {
	width: 0px;
	height: 0px;
	border-radius: 50%;
}

::-webkit-scrollbar-thumb {
	background: #808080;
	border: 0px none #FFFFFF;
	border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
	background: #808080;
}

::-webkit-scrollbar-thumb:active {
	background: #808080;
}

::-webkit-scrollbar-track {
	background: transparent;
	border: 0px none #FFFFFF;
	border-radius: 6px;
}

::-webkit-scrollbar-track:hover {
	background: transparent;
}

::-webkit-scrollbar-track:active {
	background: transparent;
}

::-webkit-scrollbar-corner {
	background: transparent;
	border-radius: 6px;
}

a {
	cursor: pointer;
	color: #0f0077;
	text-decoration: none;
}

a:hover {
	color: #0f0077;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

.MuiOutlinedInput-root {
	background: transparent !important;
	height: 48px;
}

.MuiInputLabel-outlined {
	color: #2f3435 !important;
}

.MuiOutlinedInput-input {
	padding: 30px 16px 12px 16px !important;
	font-size: 16px !important;
	line-height: 24px !important;
	font-family: "Inter", sans-serif !important;
	font-weight: 400 !important;
	color: #10141a !important;
}

select.MuiSelect-root.MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input {
	padding-left: 17px !important;
	height: auto !important;
}

.MuiInput-input {
	color: #101415 !important;
	font-size: 14px !important;
	font-style: normal !important;
	font-weight: 600 !important;
	line-height: 20px !important;
}

.MuiInputLabel-shrink {
	color: #727778 !important;
	font-size: 12px !important;
	font-style: normal !important;
	font-weight: 600 !important;
	line-height: 18px !important;
}

.MuiTextField-root label {
	color: #727778 !important;
	font-size: 12px !important;
	font-style: normal !important;
	font-weight: 600 !important;
	line-height: 18px !important;
}

.MuiOutlinedInput-notchedOutline {
	border: 1px solid #d5d8dc !important;
	border-radius: 6px !important;
}

.MuiFormControl-marginNormal {
	margin: 0px !important;
}

.Mui-focused select {
	background-color: #fff !important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
	border-color: #d5d8dc !important;
	border-radius: 6px !important;
	color: #2f3435 !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
	font-family: "Inter", sans-serif !important;
	color: #83888a !important;
	font-weight: 400 !important;
	font-size: 10px !important;
	line-height: 16px !important;
	transform: translate(16px, 10px) !important;
}

.MuiOutlinedInput-root legend {
	max-width: 0px !important;
}

.MuiFormLabel-root {
	font-style: normal !important;
	font-weight: 600 !important;
	font-size: 14px !important;
	line-height: 20px !important;
	color: #83888a !important;
}

.MuiFormHelperText-contained {
	margin-left: 0px !important;
}

.MuiCheckbox-root {
	padding: 0px !important;
	padding-right: 8px !important;
	// color: #e3e3e3 !important;
}

.MuiFormControlLabel-root {
	margin-left: 0px !important;
}

label.MuiFormControlLabel-root {
	margin-bottom: 0px !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
	color: #00465d !important;
}

.scroll::-webkit-scrollbar {
	width: 6px;
	height: 6px;
	border-radius: 50%;
}

.scroll::-webkit-scrollbar-button {
	width: 0px;
	height: 0px;
	border-radius: 50%;
}

.scroll::-webkit-scrollbar-thumb {
	background: #808080;
	border: 0px none #ffffff;
	border-radius: 6px;
}

.scroll::-webkit-scrollbar-thumb:hover {
	background: #808080;
}

.scroll::-webkit-scrollbar-thumb:active {
	background: #808080;
}

.scroll::-webkit-scrollbar-track {
	background: transparent;
	border: 0px none #ffffff;
	border-radius: 6px;
}

.scroll::-webkit-scrollbar-track:hover {
	background: transparent;
}

.scroll::-webkit-scrollbar-track:active {
	background: transparent;
}

.scroll::-webkit-scrollbar-corner {
	background: transparent;
	border-radius: 6px;
}

.hh-40 {
	font-style: normal;
	font-weight: 500;
	font-size: 40px;
	line-height: 60px;
}

.hh-32 {
	font-style: normal;
	font-weight: 400;
	font-size: 32px;
	line-height: 48px;
}

.hh-24 {
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 32px;
}

.hh-20 {
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 30px;
}

.tt-18 {
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 28px;
}

.tt-16 {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
}

.tt-14 {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
}

.tt-12 {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
}

.tt-10 {
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 16px;
}

.color-yellow-500 {
	color: #FFB71C;
}

.color-blue {
	color: #006484;
}

.color-green {
	color: #3eb288;
}

.color-gray-100 {
	color: #F3F5F8;
}

.color-gray-200 {
	color: #2f3435;
}

.color-gray-300 {
	color: #D5D8DC;
}

.color-gray-400 {
	color: #a4a9af;
}

.color-gray-500 {
	color: #747D88;
}

.color-gray-600 {
	color: #5A636E;
}

.color-gray-700 {
	color: #373E46;
}

.color-gray-800 {
	color: #293037;
}

.color-gray-900 {
	color: #14181D;
}

.color-red {
	color: #AA0000;
}

.color-white {
	color: #ffffff;
}

.color-black {
	color: #000000;
}

.color-error {
	color: #db0d15;
}

.color-primary {
	color: #082D58;
}

.color-primary-700 {
	color: #194982;
}

.bold {
	font-weight: 700;
}

.semibold {
	font-weight: 600;
}

.medium {
	font-weight: 500;
}

.regular {
	font-weight: 400;
}

.uppercase {
	text-transform: uppercase;
}

.button-primary {
	width: 100%;
	padding: 16px 0px;
	background: #082D58;
	color: #ffffff;
	border-radius: 4px;
	border: none;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
}

.button-secondary {
	width: 100%;
	padding: 16px 0px;
	background: #ffffff;
	color: #293037;
	border-radius: 4px;
	border: none;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	border: 1px solid #d5d8dc;
}

.button-error {
	width: 100%;
	padding: 16px 0px;
	background: #aa0000;
	color: #ffffff;
	border-radius: 4px;
	border: none;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	border: none;
}

.button-transparent {
	width: 100%;
	padding-top: 17px;
	padding-bottom: 19px;
	background: transparent;
	color: #bcc2c4;
	border: 1px solid #00465d;
	border-radius: 12px;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
}

.button-no-style {
	width: 100%;
	border: none;
	background: none;
}

.button-icon {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	border: 1px solid #d5d8dc;
	border-radius: 6px;
	background-color: #ffffff;

}

.wh-40 {
	width: 40px;
	height: 40px;
}

.wh-48 {
	width: 48px;
	height: 48px;
}

.form-group {
	margin-bottom: 12px;
}

input[type="file"] {
	display: none;
}

.MuiInputBase-input.Mui-disabled {
	color: rgba(0, 0, 0, 0.65) !important;
}

.MuiInputBase-input::placeholder {
	color: #767f8a !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 16px !important;
	line-height: 24px !important;
	opacity: 1 !important;
}

.MuiFormControl-root label {
	color: #767f8a !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 16px !important;
	line-height: 24px !important;
	opacity: 1 !important;
	margin-top: -10px !important;
	margin-left: 17px !important;
}

.MuiAutocomplete-tag {
	margin: 0px 3px !important;
}

.MuiInputBase-input {
	padding: 6px 8px 6px !important;
}

.MuiSelect-selectMenu {
	height: 30px !important;
}

.modal-footer {
	border: none;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	gap: 16px;
	padding: 0px 20px 20px 0px;
}

.modal-header {
	padding: 20px;
	border: none;
	background-color: #f8f9fb;
}

.modal-body {
	padding: 20px 24px;
}

.modal-content {
	border: 0 !important;
}

.estatisticaHeaderContainer {
	flex-direction: row;
	display: flex;
	align-content: space-between;
	align-items: center;
}

@media screen and (min-width: 1200px) {

	.container-xl,
	.container-lg,
	.container-md,
	.container-sm,
	.container {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}

	.estatisticaContainer {
		padding-inline: 160px;
		padding-block: 20px;
	}

}

@media screen and (min-width: 1300px) {

	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}

	.estatisticaContainer {
		padding-inline: 260px;
		padding-block: 20px;
	}
}

@media screen and (min-width: 1400px) {

	.container,
	.container-lg,
	.container-md,
	.container-sm,
	.container-xl {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}
}

@media screen and (min-width: 1530px) {
	.estatisticaContainer {
		padding-inline: 80px;
		padding-block: 20px;
	}
}

@media screen and (min-width: 1880px) {
	.estatisticaContainer {
		padding-inline: 260px;
		padding-block: 20px;
	}
}

@media screen and (max-width: 1200px) {
	.mb-16-1200 {
		margin-bottom: 16px;
	}

	.estatisticaContainer {
		padding-inline: 100px;
		padding-block: 20px;
	}
}

@media screen and (max-width: 996px) {
	.w-100-mobile {
		width: 100%;
	}
}

@media screen and (max-width: 768px) {
	.container {
		padding-left: 0px !important;
		padding-right: 0px !important;
	}

	.containerRow {
		padding-left: 20px !important;
		padding-right: 20px !important;
	}
}

/* CUSTOM LINKASH */
.btn-auth {
	width: 100%;
	padding: 16px 0px;
	border-radius: 4px;
	border: none;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
}

.btn-primary {
	border: none;
	color: #FFF;
}

.btn-color-600 {
	background: #225591;
}

.btn-color-600:is(:hover, :focus) {
	background: #2661a4;
}

.btn-color-900 {
	background: #082D58;
}

.btn-color-900:is(:hover, :focus) {
	background: #0a3b73;
}

.btn-size-default {
	padding: 12px 16px;
}

.btn-primary:disabled {
	background: #bcc2c4;
	color: #ffffff;
}

.MuiTimelineItem-missingOppositeContent:before {
	display: none;
}

.MuiTimelineSeparator-root {
	margin-top: 10px;
}

.MuiTypography-body1 {
	font-family: "Inter", sans-serif !important;
}

.p-sortable-column-icon {
	color: #848484 !important;
}

.p-column-title {
	color: #5A636E !important;
	font-size: 14px !important;
	font-weight: 500 !important;
	line-height: 24px !important;
	font-family: "Inter", sans-serif !important;
}

.p-button>span {
	font-size: 1rem;
}

.p-datatable-emptymessage {
	height: 100px !important;
	text-align: center !important;
	font-size: 18px !important;
	font-weight: 600 !important;
	font-family: "Inter", sans-serif !important;
}

.p-paginator-bottom {
	padding: 10px !important;
	background: none !important;
	border: none !important;
	text-align: right !important;
}

.p-paginator-current {
	color: #5A636E !important;
	float: left;
	font-size: 16px !important;
	font-weight: 400 !important;
	line-height: 24px !important;
	font-family: "Inter", sans-serif !important;
}

@media screen and (max-width: 40em) {
	.p-selection-column {
		display: flex !important;
		justify-content: center !important;
	}

	.p-selection-column>.p-column-title {
		display: none !important;
	}

	.p-datatable-responsive .p-datatable-thead>tr>th.p-selection-column {
		display: flex !important;
		justify-content: left !important;
		border: none !important;
		padding: 0px !important;
	}

	.p-datatable .p-datatable-thead>tr {
		align-items: center;
		display: flex;
	}

	.p-datatable-row {
		border: 1px solid #D5D8DC;
	}
}

@media screen and (max-width: 650px) {
	.p-column-title {
		font-size: 12px !important;
	}

	.p-datatable .p-datatable-tbody>tr>td {
		font-size: 12px !important;
	}

	.p-paginator-current {
		font-size: 14px !important;
	}

	.p-paginator .p-paginator-pages .p-paginator-page {
		font-size: 14px !important;
	}
}

@media screen and (max-width: 800px) {
	.p-paginator-current {
		width: 100% !important;
		text-align: left !important;
	}
}

.p-paginator.p-paginator-pages {
	margin: 0;
}

.p-paginator-element.p-link {
	height: 30px !important;
	width: 30px !important;
	min-width: 30px !important;
	font-style: normal !important;
	font-weight: 400 !important;
	font-size: 16px !important;
	line-height: 24px !important;
	color: #5A636E !important;
	background: none !important;
	border: none !important;
	font-family: "Inter", sans-serif !important;
}

.p-paginator-element.p-link.p-highlight {
	background: none !important;
	color: #194982 !important;
	font-weight: 500 !important;
}

.p-button {
	margin: 0;
	border-radius: 0.25rem !important;
	border: none !important;
}

.p-datatable .p-datatable-thead>tr>th {
	text-align: left !important;
	background-color: #fff !important;
	border: 1px solid #f4f4f4 !important;
}

.p-datatable .p-datatable-thead>tr {
	height: 50px !important;
}

.p-datatable .p-datatable-tbody>tr>td {
	border: 1px solid #f4f4f4 !important;
}

.p-datatable .p-datatable-tbody>tr.p-highlight {
	background-color: #E7F2FF !important;
	color: #14181D !important;
}

.p-datatable-header {
	height: 60px;
	background-color: #fff !important;
	border: none !important;
	padding-right: 0 !important;
}

.p-datatable-loading-overlay.p-component-overlay {
	background-color: #fff !important;
}

.p-datatable-loading-content {
	top: 15% !important;
}

.p-datatable-tbody td {
	word-break: break-all;
	white-space: wrap;
	text-overflow: ellipsis;
	font-size: 16px !important;
	font-weight: 400 !important;
	font-family: "Inter", sans-serif !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
	border-color: #CBD3DC !important;
	background-color: #2C64A5 !important;
	color: #FFF;
}

.p-paginator-icon.pi-caret-left:before {
	content: "\e931";
}

.p-paginator-icon.pi-caret-right:before {
	content: "\e932";
}

.swal2-styled.swal2-confirm {
	background: #082D58;
	border: none;
}

.swal2-styled.swal2-confirm:is(:hover, :focus) {
	background: #0a3b73;
	border: none;
}

.p-component {
	font-family: "Inter", sans-serif !important;
}

.p-calendar-button {
	position: inherit !important;
}

.p-datepicker table td>span.p-highlight {
	background-color: #2C64A5 !important;
}

/* Mobile */
@media screen and (max-width: 520px) {
	body {
		zoom: 80%;
	}
}