.headerContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	padding: 0px 24px;
	height: 80px;
	background-color: #082D58;
}

.logo {
	width: 150px;
	height: 35px;
}

.containerLeft {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 64px;
}

.containerRight {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 40px;
}

.avatar {
	width: 48px;
	height: 48px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #2CA57A;
	border-radius: 28px;
	background-repeat: no-repeat;
	user-select: none;
	/* Safari */
	-webkit-user-select: none;
	/* Firefox */
	-moz-user-select: none;
	/* IE10+/Edge */
	-ms-user-select: none;
}

.avatar p {
	margin: 0px;
	font-size: 20px;
	font-weight: 500;
	line-height: 30px;
	color: #FFF;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
		transform: translateY(-10px);
		/* Slide up slightly */
	}

	100% {
		opacity: 1;
		transform: translateY(0);
		/* End in the original position */
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
		transform: translateY(0);
	}

	100% {
		opacity: 0;
		transform: translateY(-10px);
		/* Slide up slightly */
	}
}

.menu {
	background-color: #ffffff;
	position: absolute;
	width: 400px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	top: 75px;
	right: 24px;
	border-radius: 8px;
	z-index: 100;
	box-shadow: 4px 4px 10px 0px #767f8a26;
	opacity: 0;
}

.fadeIn {
	animation: fadeIn 0.3s ease-in-out forwards;
}

.fadeOut {
	animation: fadeOut 0.3s ease-in-out forwards;
}

.perfil {
	padding: 12px 24px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 16px;
	width: 100%;
	background-color: #f8f9fb;
	border-radius: 8px 8px 0 0;
}

.opcao {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	gap: 12px;
	background-color: #ffffff;
	border-bottom: 1px solid #d5d8dc;
	width: 100%;
	height: 55px;
	cursor: pointer;
}

.opcao:hover {
	background-color: #f8f9fb;
}

.opcao div {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 12px;
	padding: 0px 24px;
}

@media screen and (max-width: 520px) {
	.menu {
		zoom: 80%
	}
}