.header {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 0px 60px;
	height: 100px;
}

.logo {
	width: 200px;
	height: 45px;
}

.botaoSuporte {
	padding: 12px 16px;
	background-color: #ffffff;
	border: 1px solid #d5d8dc;
	border-radius: 4px;
	cursor: pointer;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 10px;
}

.logoSuporte {
	width: 22px;
	height: 22px;
}